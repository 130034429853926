import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { JobType } from 'wmic-pe-portals-utils-js';
import WMICPASummaryUtil from "wmic-pe-capability-gateway-quoteandbind-pa-react/util/WMICPASummaryUtil";
import { ReadOnlyCoverageTable } from 'wmic-pe-capability-quoteandbind-common-react';
import WMICIterableVehicleComponent from "../WMICIterableVehicleComponent/WMICIterableVehicleComponent";
import WMICIterableDriverComponent from "../WMICIterableDriverComponent/WMICIterableDriverComponent";
import metadata from './WMICVehiclesDriversInfo.metadata.json5';
import messages from './WMICVehiclesDriversInfo.messages';

function WMICVehiclesDriversInfo(props) {
    const { paDataVM, jobVM } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const isPolicyChangeOrRenewal = _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE;

    const driversList = useMemo(() => {
        const formattedDriversList = paDataVM.coverables.drivers.children.map((driverVM) => {
            if (isPolicyChangeOrRenewal) {
                driverVM = WMICPASummaryUtil.addVehicleDriversType(jobVM.vehicles.children, driverVM, true);
            } else {
                driverVM = WMICPASummaryUtil.addVehicleDriversType(paDataVM.coverables.vehicles.children, driverVM, false);
            }

            return driverVM;
        });

        return formattedDriversList;
    }, [paDataVM.coverables.drivers.children]);

    if (_.isEmpty(paDataVM)) {
        return null;
    }

    const getVehicleCoverages = (personalAutoData, riskPublicID) => {
        const vehicleCoverages = paDataVM.offerings.children[0].coverages.vehicleCoverages.children.find(
            (vehicleCoverage) => {
                return vehicleCoverage.value.publicID === riskPublicID;
            }
        );

        return vehicleCoverages ? vehicleCoverages.coverages : [];
    }

    const getVehicleNonCoverageCosts = (vehiclePublicID) => {
        const vehicleNonCoverageCosts = paDataVM.offerings.children[0].value.personalVehicleNonCoverageCosts?.find(
            (nonCoverageCostsPerVehicle) => {
                return nonCoverageCostsPerVehicle.vehiclePublicID === vehiclePublicID;
            }
        );

        return vehicleNonCoverageCosts?.personalVehicleNonCoverageCosts ? vehicleNonCoverageCosts.personalVehicleNonCoverageCosts : [];
    }

    const shouldDisplayLineCoverage = (coverage) => (
        coverage.category_WMIC.value === 'PALineAdditional_WMIC' ||
        coverage.category_WMIC.value === 'PALineCommercial_WMIC' ||
        coverage.category_WMIC.value === 'PALineMTCGrp_WMIC' ||
        coverage.category_WMIC.value === 'PAOptionalAccBenefits_WMIC') && coverage.selected.value

    const getPolicyLevelCoverages = () => {
        const lineCoverages = paDataVM.offerings.children[0].coverages.lineCoverages.children;
        const lineLevelCoverages = _.get(jobVM, 'value.lineLevelCoverageComparisons');
        const policyLevelCoverages = [];

        if (isPolicyChangeOrRenewal) {
            if (!_.isEmpty(lineLevelCoverages)) {
                lineCoverages.forEach((coverage) => {
                    if (shouldDisplayLineCoverage(coverage)) {
                        const cov = coverage.value;
                        const lineLevelCov = _.find(lineLevelCoverages, {
                            coveragePatternCode: cov.coverageUniqueID
                        });
                        if (lineLevelCov) {
                            cov.amount = lineLevelCov.annualPremium ?? undefined;
                            cov.limit = lineLevelCov.limit ?? undefined;
                            cov.deductible = lineLevelCov.deductible ?? undefined;
                            cov.deleted = lineLevelCov.deleted ?? undefined;
                        }

                        policyLevelCoverages.push(cov);
                    }
                });
            }
        } else {
            lineCoverages.forEach((coverage) => {
                if (shouldDisplayLineCoverage(coverage)) {
                    policyLevelCoverages.push(coverage.value);
                }
            });
        }

        return policyLevelCoverages;
    }

    const _iterableProps = () => {
        const vehiclesInPolicyVM = isPolicyChangeOrRenewal ? jobVM.vehicles.children : paDataVM.coverables.vehicles.children;
        const _vehicleOverrides = vehiclesInPolicyVM.map((vehicle, index) => {
            let _vehicleVM;
            let _vehicleCoveragesVM;
            let _vehicleNonCoverageCostsVM;
            let _isVehicleDeleted;
            const _lineCoveragesVM = paDataVM.offerings.children[0].coverages.lineCoverages.children;

            if (!isPolicyChangeOrRenewal) {
                _vehicleVM = paDataVM.coverables.vehicles.children[index];
                _vehicleCoveragesVM = getVehicleCoverages(paDataVM, _vehicleVM.publicID.value);
                _isVehicleDeleted = false;
            } else {
                _vehicleVM = jobVM.vehicles.children[index].vehicle;
                _isVehicleDeleted = jobVM.vehicles.children[index].deleted.value;
                if (_isVehicleDeleted) {
                    _.set(_vehicleVM, 'totalVehicleCost_Ext.amount', 0);
                }
                _vehicleCoveragesVM = _isVehicleDeleted ? undefined : getVehicleCoverages(paDataVM, _vehicleVM.publicID.value);
            }

            _vehicleNonCoverageCostsVM = getVehicleNonCoverageCosts(_vehicleVM.publicID.value);

            const _vehicleOverride = {
                [`iterableVehicleComponent${index}`]: {
                    vehicle: _vehicleVM,
                    vehicleCoverages: _vehicleCoveragesVM,
                    vehicleNonCoverageCosts: _vehicleNonCoverageCostsVM,
                    rateAsOfDate: paDataVM.rateAsOfDate,
                    lineCoverages: _lineCoveragesVM,
                    isPolicyChangeOrRenewal,
                    jobVM,
                    isVehicleDeleted: _isVehicleDeleted
                }
            };

            return Object.assign({}, _vehicleOverride);
        });

        const _driverOverrides = driversList.map((driver, index) => {
            const _driver = driversList[index];

            const _driverOverride = {
                [`iterableDriverComponent${index}`]: {
                    driver: _driver
                }
            }
            return Object.assign({}, _driverOverride);
        })

        return Object.assign({}, ..._vehicleOverrides, ..._driverOverrides);
    }

    function getCell(items, index, property) {
        return !_.isObject(items[property.id]) ? items[property.id] : null;
    }

    const getAvailableVehicles = () => {
        if (isPolicyChangeOrRenewal) {
            return jobVM.vehicles.children;
        }

        return paDataVM.coverables.vehicles.children;
    }


    const overrides = {
        vehicleTableIterableContainer: {
            data: getAvailableVehicles()
        },
        driverTableIterableContainer: {
            data: driversList
        },
        policyLevelCoveragesContainer: {
            visible: !_.isEmpty(getPolicyLevelCoverages()),
        },
        policyLevelCoverages: {
            coverageHeaderText: messages.description,
            coverageList: getPolicyLevelCoverages(),
            isPolicyChangeOrRenewal
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveCallbackMap: {
            getCellCoverage: getCell
        },
        resolveComponentMap: {
            WMICIterableDriverComponent,
            WMICIterableVehicleComponent,
            ReadOnlyCoverageTable
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={paDataVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICVehiclesDriversInfo.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICVehiclesDriversInfo;
