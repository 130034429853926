/* eslint-disable no-secrets/no-secrets */
import _ from 'lodash';
import { CONSTANTS, PAConstants, LINE_OF_BUSINESS, WMICVehicleUtil, POLICY_DETAILS, WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import { serviceUrls } from 'app-config';

export const CONSENT_DOCUMENT_URL = {};
const CREDIT_CONSENT_VEHICLE_TYPES = ['auto', 'motorhome'];
const VEHICLE_BUSINESS_SEGMENT_PERSONAL_CODE = 'Personal';
const CREDIT_CONSENT_RECREATIONAL_VEHICLE_TYPES = ['snow', 'motorcycle', 'trailer', 'atv'];

const { dotcmsHost } = serviceUrls;
Object.defineProperties(CONSENT_DOCUMENT_URL, {
    creditConsentDocumentNB: {
        value: `${dotcmsHost}/bluepass/credit-consent/1001-1001F-042221%20PL%20Auto%20Express%20Consent%20Credit%20Info%20Apr2021_FILLABLE%20FIN.pdf?language_id=1`,
        writable: false,
        configurable: false
    },
    creditConsentDocumentNSPE: {
        value: `${dotcmsHost}/brokerportal/form/1001-042221%20PL%20Auto%20Express%20Consent%20Credit%20Info%20Apr2021_FILLABLE%20FIN.pdf?language_id=1`,
        writable: false,
        configurable: false
    },
    creditConsentDocumentABYT: {
        value: `${dotcmsHost}/bluepass/credit-consent/1002-021423%20PL%20Auto%20Express%20Consent%20Credit%20Info%20ABYK_FILLABLE_FIN.pdf?language_id=1`,
        writable: false,
        configurable: false
    },
    creditConsentDocumentAB: {
        value: `${dotcmsHost}/bluepass/credit-consent/1002-021423%20PL%20Auto%20Express%20Consent%20Credit%20Info%20ABYK_FILLABLE_FIN.pdf?language_id=1`,
        writable: false,
        configurable: false
    },
    creditConsentDocumentYT: {
        value: `${dotcmsHost}/bluepass/credit-consent/1002-021423%20PL%20Auto%20Express%20Consent%20Credit%20Info%20ABYK_FILLABLE_FIN.pdf?language_id=1`,
        writable: false,
        configurable: false
    },
    creditConsentDocumentRecVehicleAB: {
        value: `${dotcmsHost}/bluepass/credit-consent/1002-021423%20PL%20Auto%20Express%20Consent%20Credit%20Info%20ABYK_FILLABLE_FIN.pdf?language_id=1`,
        writable: false,
        configurable: false
    },
    paymentConsentDocumentABYT: {
        value: `${dotcmsHost}/bluepass/credit-consent/1002-021423%20PL%20Auto%20Express%20Consent%20Credit%20Info%20ABYK_FILLABLE_FIN.pdf?language_id=1`,
        writable: false,
        configurable: false
    },
    defaultConsentDocument: {
        value: '#',
        writable: false,
        configurable: false
    }
});

export default class WMICCreditConsentUtil {
    constructor (rpcUtil, jurisdiction, rateAsOfDate, vehicles) {
        this.jurisdiction = jurisdiction;
        this.consentVersionChecks = this.createConsentVersionChecks(rpcUtil, rateAsOfDate);

        this.showPaymentPlanConsent = WMICCreditConsentUtil.showPaymentPlan(jurisdiction);

        if (this.showPaymentPlanConsent) {
            this.hasCreditableVehicle = WMICCreditConsentUtil.hasPersonalVehicleOrMotorHome(vehicles);
            this.recreationVehiclesOnly = this.hasRecreationalVehicleOnly(vehicles);
        }
        this.showFormRecVehicleOnly = this.consentVersionChecks['1698'] && this.recreationVehiclesOnly;
        this.showAllForms = this.showPaymentPlanConsent && this.hasCreditableVehicle;
        this.showTwoInOneForm = this.showAllForms && this.consentVersionChecks['1611'];
    }

    static HO_CREDIT_CONSENT_ENHANCEMENT_DATE = new Date(2021, 5, 15);

    static hasOnlyIRCAVehicles(vehicles) {
        return vehicles
            && vehicles.length > 0
            && !vehicles.some((vehicle) =>
                vehicle.businessSegment_WMIC !== PAConstants.vehicleBusinessSegmentCommercial
            );
    }

    static isPniCreditConsentable (pni) {
        const subtype = _.get(pni, 'subtype.value');
        const estateOf = _.get(pni, 'estateOf_WMIC.value');
        return subtype === CONSTANTS.Person && !estateOf;
    };

    static isPersonCreditConsentable (person) {
        //Can be used for both PNI's and ANI's
        const estateOf = _.get(person, 'estateOf_WMIC.value');
        return person?.subtype?.value?.toLowerCase() === CONSTANTS.Person.toLowerCase() && !estateOf;
    };

    static showPaymentPlan(jurisdiction) {
        return ['AB', 'YT'].includes(jurisdiction);
    };

    static isHOCreditConsentTabVisibleOnInsuranceHistory(policyEffectiveDate, anis) {
        return (new Date(policyEffectiveDate).getTime() >= WMICCreditConsentUtil.HO_CREDIT_CONSENT_ENHANCEMENT_DATE.getTime())
            || this.isAnyAniCreditConsentable(anis);
    };

    static isCreditInfoWithdrawalConsentApplicable(consentOption, rateAsOfDate, jurisdiction, lob, vehicles) {
        const isPersonalAuto = this.isPersonalAuto(lob, vehicles);
        return this.isCreditFieldsApplicable(rateAsOfDate, jurisdiction, isPersonalAuto)
            && [CONSTANTS.CREDIT_CONSENT_CODES.NO, CONSTANTS.CREDIT_CONSENT_CODES.WITHDRAWN].includes(consentOption);
    };

    hasRecreationalVehicleOnly(vehicles) {
        return vehicles && vehicles.every((vehicle) => (vehicle.businessSegment_WMIC === VEHICLE_BUSINESS_SEGMENT_PERSONAL_CODE
            && CREDIT_CONSENT_RECREATIONAL_VEHICLE_TYPES.includes((vehicle.vehicleType))));
    }

    static hasPersonalVehicleOrMotorHome(vehicles) {
        let found = false;

        if(vehicles) {
            const isCreditCheckedVehicle = (vehicle) =>
                CREDIT_CONSENT_VEHICLE_TYPES.includes(vehicle.vehicleType)
                    && vehicle.businessSegment_WMIC === VEHICLE_BUSINESS_SEGMENT_PERSONAL_CODE;

            for(let i = 0, j = vehicles.length - 1; !found && i <= (j - i); i+=1) {
                found = isCreditCheckedVehicle(vehicles[i]) || isCreditCheckedVehicle(vehicles[j-i])
            }
        }

        return found;
    };

    static isPersonalAuto(lob, vehicles) {
        return lob === LINE_OF_BUSINESS.PERSONAL_AUTO
            && vehicles
            && !vehicles.some((veh) => WMICVehicleUtil.isCommerciallyRatedVehicleType(veh.businessSegment_WMIC, veh.vehicleType));
    };

    static isCreditFieldsApplicable(rateAsOfDate, jurisdiction, isPersonalAuto) {
        const creditFieldsEffectiveDate = new Date(2021, 3, 1);
        return !(isPersonalAuto
            && POLICY_DETAILS.BLUEPASS_SUPPORTED_JURISDICTIONS_EXCEPT_QC.includes(jurisdiction)
            && WMICDateTimeService.compareIgnoreTime(WMICDateTimeService.localDate2Date(rateAsOfDate), creditFieldsEffectiveDate) >= 0);
    };

    static isAniCreditConsentable (ani) {
        return ani?.subtype?.value?.code === CONSTANTS.Person.toLowerCase()
            && !ani.estateOf_WMIC.value;
    };

    static getCreditConsentableAnis(jobVM, viewModelService) {
        const anis = _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.children', []);
        return anis.filter((ani) => {
            const _ani = this.populateContextIfEmpty(ani, jobVM, viewModelService, {"LobContext": jobVM.lob.value});
            return this.isAniCreditConsentable(_ani);
        })
    }

    static updateAdditionalNamedInsured(jobVM, additionalNamedInsureds, updatedAni, index) {
        const allConsentableAnis = [...additionalNamedInsureds];
        allConsentableAnis[index] = updatedAni;

        const allAnis = _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.children', []).map((ani) => {
            if (!WMICCreditConsentUtil.isAniCreditConsentable(ani)) {
                return ani.value;
            }
            const newAniIndex = allConsentableAnis.findIndex((consentableAni) => consentableAni?.contactPublicID?.value === ani?.contactPublicID?.value);
            if (newAniIndex > -1) {
                return allConsentableAnis[newAniIndex].value;
            }
            return ani.value;
        });
        _.set(jobVM, 'baseData.additionalNamedInsureds_WMIC.value', allAnis);
        return allConsentableAnis;
    }

    static syncBaseDataToDriverLobData (selectedDriver, baseData) {
        if (selectedDriver && baseData) {
            if (selectedDriver.isPrimaryInsured_WMIC) {
                baseData.creditConsentReceived = selectedDriver.creditConsentReceived;
                baseData.creditConsentDate = selectedDriver.creditConsentDate;

                this.syncBaseDataSubQuestionsToDriverData(baseData, selectedDriver);
            }
            else {
                const foundANI = baseData.additionalNamedInsureds_WMIC.find((ani) =>
                    ani.contactPublicID === selectedDriver.person.publicID);

                if (foundANI) {
                    foundANI.creditConsentReceived = selectedDriver.creditConsentReceived;
                    foundANI.creditConsentDate = selectedDriver.creditConsentDate;

                    this.syncBaseDataSubQuestionsToDriverData(foundANI, selectedDriver);
                }
            }
        }
    };

    static syncBaseDataSubQuestionsToDriverData(baseData, selectedDriver) {
        if (baseData.creditConsentReceived === CONSTANTS.CREDIT_CONSENT_CODES.YES) {
            baseData.personalInfoConsentForm = selectedDriver.personalInfoConsentForm;
        } else {
            baseData.creditInfoWithdrawalConsent = selectedDriver.creditInfoWithdrawalConsent;
        }
    };

    createConsentVersionChecks(rpcUtil, rateAsOfDate) {
        const consentVersionChecks = {};

        switch (this.jurisdiction) {
            case 'AB':
                consentVersionChecks['1611'] = rpcUtil.getIsRPCEffective(this.jurisdiction, rateAsOfDate, '1611');
                consentVersionChecks['1698'] = rpcUtil.getIsRPCEffective(this.jurisdiction, rateAsOfDate, '1698');
                break;
            case 'YT':
                consentVersionChecks['1611_DateOnly'] = WMICDateTimeService.localDate2Date(rateAsOfDate) >= WMICDateTimeService.localDate2Date(rpcUtil.getRPCDate('1611_AB'));
                break;
            default:
                break;
        }

        return consentVersionChecks;
    };

    getCreditConsentAgreement() {
        let consentAgreementURL;

        switch(this.jurisdiction) {
            case 'NB':
                consentAgreementURL = CONSENT_DOCUMENT_URL.creditConsentDocumentNB;
                break;
            case 'NS':
            case 'PE':
                consentAgreementURL = CONSENT_DOCUMENT_URL.creditConsentDocumentNSPE;
                break;
            case 'AB':
                if(this.showFormRecVehicleOnly) {
                    consentAgreementURL = CONSENT_DOCUMENT_URL.creditConsentDocumentRecVehicleAB;
                }
                else if(this.consentVersionChecks['1611']) {
                    consentAgreementURL=  CONSENT_DOCUMENT_URL.creditConsentDocumentAB;
                }
                else {
                    consentAgreementURL = CONSENT_DOCUMENT_URL.creditConsentDocumentABYT;
                }
                break;
            case 'YT':
                consentAgreementURL = this.consentVersionChecks['1611_DateOnly']
                    ? CONSENT_DOCUMENT_URL.creditConsentDocumentYT
                    : CONSENT_DOCUMENT_URL.creditConsentDocumentABYT;
                break;
            default:
                consentAgreementURL = CONSENT_DOCUMENT_URL.defaultConsentDocument;
                break;
        }

        return consentAgreementURL;
    };

    getConsentAgreementLabelKey() {
        let labelKey;

        if (this.showAllForms || this.showFormRecVehicleOnly) {
            labelKey = (this.showTwoInOneForm || this.showFormRecVehicleOnly)
                ? 'quoteandbind.views.credit-consent.ExpressConsentCreditAgreement'
                : 'quoteandbind.views.credit-consent.DiscountConsentAgreement';
        }

        return labelKey;
    };

    getPaymentPlanAgreement() {
        let paymentPlanAgreementURL;

        if (!this.showTwoInOneForm && !this.showFormRecVehicleOnly) {
            switch (this.jurisdiction) {
                case 'AB':
                case 'YT':
                    paymentPlanAgreementURL = CONSENT_DOCUMENT_URL.paymentConsentDocumentABYT;
                    break;
                default:
                    paymentPlanAgreementURL = CONSENT_DOCUMENT_URL.defaultConsentDocument;
                    break;
            }
        }

        return paymentPlanAgreementURL;
    };

    static isAnyAniCreditConsentable (anis) {
        let found = false;

        if(anis) {
            const isCreditCheckedPerson = (ani) =>
                ani.subtype.toLowerCase() === CONSTANTS.Person.toLowerCase() && !ani.estateOf_WMIC;

            for(let i = 0, j = anis.length - 1; !found && i <= (j - i); i+=1) {
                found = isCreditCheckedPerson(anis[i]) || isCreditCheckedPerson(anis[j-i])
            }
        }

        return found;
    }

    static isContextEmpty (context) {
        if (_.isEmpty(context) || _.isNil(context)) {
            return true;
        }
        const isContextCorrupted = Object.entries(context).every(([key, value]) => (
            key === '[object Object]' && _.isNil(value)
        ))
        return !!isContextCorrupted;
    };

    static populateContextIfEmpty(property, jobVM, viewModelService, contextArgs) {
        if (this.isContextEmpty(property?.aspects?.context())) {
            const args = contextArgs ?
                { ...jobVM.aspects.context(), ...contextArgs } :
                jobVM.aspects.context();
            return viewModelService.changeContext(
                property,
                args
            );
        }
        return property;
    }

    static updateAniProps(additionalNamedInsureds, setAdditionalNamedInsured, onValidateConsentMap, showErrors, readOnly) {
        const aniOverrides = additionalNamedInsureds.map((ani, aniIndex) => {
            const newOverrides = {
                [`creditConsentANIComponent${aniIndex}`]: {
                    setData: setAdditionalNamedInsured,
                    onValidateConsentMap,
                    showErrors,
                    readOnly,
                }
            }
            return newOverrides;
        })
        return Object.assign({}, ...aniOverrides);
    };

    static isConsentValid(consentValidMap) {
        return Object.values(consentValidMap).every((cValid) => cValid === true);
    }
}
